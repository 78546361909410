<template>
  <div :class="{ container: !parentType }" class="my-20 paragraph--margin">
    <div
      v-if="location?.linkUrl && location.imageUrl"
      class="w-full max-w-[333px]"
    >
      <MapImage v-bind="location" />
      <address class="my-10 not-italic">
        <h3 v-blokkli-editable:field_title class="font-bold pb-5">
          {{ title }}
        </h3>

        <span v-if="additionalInformation" v-blokkli-editable:field_text>
          {{ additionalInformation }}<br />
        </span>

        <template v-if="address">
          {{ address.addressLine1 }}<br />
          <template v-if="address.addressLine2">
            {{ address.addressLine2 }}<br />
          </template>

          {{ address.postalCode }} {{ address.locality }}
        </template>
      </address>
    </div>

    <ButtonAddress v-else :name="title">
      <div class="font-normal">
        <span v-if="additionalInformation">
          {{ additionalInformation }}<br />
        </span>

        <template v-if="address">
          {{ address.addressLine1 }}<br />
          <template v-if="address.addressLine2">
            {{ address.addressLine2 }}<br />
          </template>

          {{ address.postalCode }} {{ address.locality }}
        </template>
      </div>
    </ButtonAddress>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphPlaceFragment } from '#graphql-operations'

const { parentType } = defineBlokkli({
  bundle: 'place',
  editor: {
    editTitle: (el) => el.querySelector('h3')?.innerText,
  },
})

defineProps<{
  title?: ParagraphPlaceFragment['title']
  address?: ParagraphPlaceFragment['address']
  location?: ParagraphPlaceFragment['location']
  additionalInformation?: ParagraphPlaceFragment['additionalInformation']
}>()

defineOptions({
  name: 'ParagraphPlace',
})
</script>
