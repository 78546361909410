<template>
  <Accordion
    v-if="isAccordion && structuredTable"
    :title="title || $texts('table', 'Tabelle')"
    :class="{ 'paragraph--margin': !parentType }"
  >
    <StructuredTable
      v-bind="structuredTable"
      :id="id"
      :mobile-style="options.mobileStyle"
    />
  </Accordion>
  <div
    v-else-if="structuredTable || isEditing"
    :id="id"
    :class="{
      'container my-20 lg:my-30 xl:my-50 paragraph--margin':
        !parentType && fieldListType !== 'nested',
    }"
  >
    <h3 v-if="title" class="text-left font-bold mb-10 text-xl">
      {{ title }}
    </h3>
    <StructuredTable
      v-if="structuredTable"
      v-bind="structuredTable"
      :mobile-style="options.mobileStyle"
      @click.capture="onClick"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphTableFragment } from '#graphql-operations'
import { slugify } from '~/helpers/slugify'

const { onClick } = useClickTriggerProxy()

const { parentType, options, isEditing, fieldListType } = defineBlokkli({
  bundle: 'table',
  globalOptions: ['showInToc'],
  options: {
    mobileStyle: {
      type: 'radios',
      label: 'Mobile Darstellung',
      default: 'vertical',
      options: {
        vertical: 'Vertikal',
        horizontal: 'Horizontal',
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('figcaption')?.innerText,
  },
})

const props = defineProps<{
  structuredTable: ParagraphTableFragment['structuredTable']
  title?: ParagraphTableFragment['title']
}>()

const isAccordion = computed(() => parentType.value === 'expand_section')

const id = computed(() => slugify(props.title))

defineOptions({
  name: 'ParagraphTable',
})
</script>
