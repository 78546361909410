<template>
  <div
    v-if="text || isEditing"
    :class="{
      'container my-20 lg:mb-30 xl:pr-220 paragraph--margin':
        !parentType && fieldListType !== 'nested',
    }"
  >
    <ClickTriggerProxy>
      <div
        v-blokkli-editable:paragraphs_text="{ type: 'frame' }"
        class="ck-content hyphens-auto lg:hyphens-none"
        :class="{
          'is-small': isSmall,
        }"
        v-html="text"
      />
    </ClickTriggerProxy>
  </div>
</template>

<script lang="ts" setup>
const { parentType, isEditing, fieldListType } = defineBlokkli({
  bundle: 'text',
  editor: {
    editTitle: (el) => el.innerText,
    getDraggableElement: (el) => el.querySelector('.ck-content'),
  },
})

defineProps<{
  text?: string
}>()

const isSmall = computed(() => parentType.value === 'filter_page_item')

defineOptions({
  name: 'ParagraphText',
})
</script>
