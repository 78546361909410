<template>
  <div
    class="my-20 lg:my-30 xl:my-40 paragraph--margin"
    :class="{ container: !parentType }"
  >
    <DrupalLink
      v-if="buttonUrl"
      v-blokkli-editable:field_title
      :to="buttonUrl"
      class="button is-super is-link is-limited"
      :class="{ 'is-strong': options.type === 'functional' }"
    >
      {{ buttonTitle }}
    </DrupalLink>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphButtonFragment } from '#graphql-operations'

const { options, parentType } = defineBlokkli({
  bundle: 'button',
  options: {
    type: {
      type: 'radios',
      label: 'Darstellung',
      default: 'navigation',
      options: {
        navigation: 'Navigation',
        functional: 'Funktional',
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('a')?.innerText,
    mockProps: (text) => {
      return {
        title: text || 'Titel vom Button',
        link: {
          uri: {
            entityPath: '/',
          },
        },
      }
    },
    getDraggableElement: (el) => el.querySelector('a'),
  },
})

const props = defineProps<{
  title?: ParagraphButtonFragment['title']
  link: ParagraphButtonFragment['link']
}>()

const linkedEntity = computed(() => {
  if (props.link?.uri && 'entity' in props.link.uri) {
    return props.link.uri.entity
  }
  return undefined
})

const buttonUrl = computed(() => {
  // If entity is MediaDocument.
  if (linkedEntity?.value?.__typename === 'MediaDocument') {
    // Use originalFileUrl for documents.
    if (
      'originalFileUrl' in linkedEntity.value &&
      linkedEntity.value.originalFileUrl
    ) {
      return linkedEntity.value.originalFileUrl
    }

    // If URL is given.
    if (linkedEntity.value.media?.entity?.uriRawField?.first?.url) {
      return linkedEntity.value.media?.entity?.uriRawField?.first?.url
    }
  }

  return props.link?.uri?.entityPath
})

const buttonTitle = computed(() => {
  if (props.title) {
    return props.title
  }

  if (linkedEntity.value && 'teaserTitle' in linkedEntity.value) {
    return linkedEntity.value.teaserTitle
  } else if (linkedEntity.value && 'name' in linkedEntity.value) {
    return linkedEntity.value.name
  }

  return ''
})

defineOptions({
  name: 'ParagraphButton',
})
</script>
